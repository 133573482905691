import { Code } from "@bufbuild/connect";
import { SubmissionRequest_SubmissionType, } from "../../proto/qf/requests_pb";
import { Enrollment_DisplayState, Enrollment_UserStatus, Group, Submission, User } from "../../proto/qf/types_pb";
import { Color, ConnStatus, getStatusByUser, hasAllStatus, hasStudent, hasTeacher, isPending, isStudent, isTeacher, isVisible, newID, setStatusAll, setStatusByUser, SubmissionStatus, validateGroup } from "../Helpers";
import * as internalActions from "./internalActions";
export const internal = internalActions;
export const onInitializeOvermind = async ({ actions, effects }) => {
    effects.api.init(actions.errorHandler);
    await actions.fetchUserData();
    const alert = localStorage.getItem("alert");
    if (alert) {
        actions.alert({ text: alert, color: Color.RED });
        localStorage.removeItem("alert");
    }
};
export const handleStreamError = (context, error) => {
    context.state.connectionStatus = ConnStatus.DISCONNECTED;
    context.actions.alert({ text: error.message, color: Color.RED, delay: 10000 });
};
export const receiveSubmission = ({ state }, submission) => {
    let courseID = 0n;
    let assignmentOrder = 0;
    Object.entries(state.assignments).forEach(([, assignments]) => {
        const assignment = assignments.find(a => a.ID === submission.AssignmentID);
        if (assignment && assignment.CourseID !== 0n) {
            assignmentOrder = assignment.order;
            courseID = assignment.CourseID;
            return;
        }
    });
    if (courseID === 0n) {
        return;
    }
    Object.assign(state.submissions[courseID.toString()][assignmentOrder - 1], submission);
};
export const getSelf = async ({ state, effects }) => {
    const response = await effects.api.client.getUser({});
    if (response.error) {
        return false;
    }
    state.self = response.message;
    return true;
};
export const getEnrollmentsByUser = async ({ state, effects }) => {
    const response = await effects.api.client.getEnrollments({
        FetchMode: {
            case: "userID",
            value: state.self.ID,
        }
    });
    if (response.error) {
        return;
    }
    state.enrollments = response.message.enrollments;
    for (const enrollment of state.enrollments) {
        state.status[enrollment.courseID.toString()] = enrollment.status;
    }
};
export const getUsers = async ({ state, effects }) => {
    const response = await effects.api.client.getUsers({});
    if (response.error) {
        return;
    }
    for (const user of response.message.users) {
        state.users[user.ID.toString()] = user;
    }
    state.allUsers = response.message.users.sort((a, b) => {
        if (a.IsAdmin > b.IsAdmin) {
            return -1;
        }
        if (a.IsAdmin < b.IsAdmin) {
            return 1;
        }
        return 0;
    });
};
export const updateUser = async ({ actions, effects }, user) => {
    const response = await effects.api.client.updateUser(user);
    if (response.error) {
        return;
    }
    await actions.getSelf();
};
export const getCourses = async ({ state, effects }) => {
    state.courses = [];
    const response = await effects.api.client.getCourses({});
    if (response.error) {
        return;
    }
    state.courses = response.message.courses;
};
export const updateAdmin = async ({ state, effects }, user) => {
    if (confirm(`Are you sure you want to ${user.IsAdmin ? "demote" : "promote"} ${user.Name}?`)) {
        const req = new User(user);
        req.IsAdmin = !user.IsAdmin;
        const response = await effects.api.client.updateUser(req);
        if (response.error) {
            return;
        }
        const found = state.allUsers.findIndex(s => s.ID === user.ID);
        if (found > -1) {
            state.allUsers[found].IsAdmin = req.IsAdmin;
        }
    }
};
export const getEnrollmentsByCourse = async ({ state, effects }, value) => {
    const response = await effects.api.client.getEnrollments({
        FetchMode: {
            case: "courseID",
            value: value.courseID,
        },
        statuses: value.statuses,
    });
    if (response.error) {
        return;
    }
    state.courseEnrollments[value.courseID.toString()] = response.message.enrollments;
};
export const setEnrollmentState = async ({ effects }, enrollment) => {
    enrollment.state = isVisible(enrollment)
        ? Enrollment_DisplayState.HIDDEN
        : Enrollment_DisplayState.VISIBLE;
    await effects.api.client.updateCourseVisibility(enrollment);
};
export const updateSubmission = async ({ state, effects }, { owner, submission, status }) => {
    if (!submission) {
        return;
    }
    switch (owner.type) {
        case "ENROLLMENT":
            if (getStatusByUser(submission, submission.userID) === status) {
                return;
            }
            break;
        case "GROUP":
            if (hasAllStatus(submission, status)) {
                return;
            }
            break;
    }
    if (!confirm(`Are you sure you want to set status ${SubmissionStatus[status]} on this submission?`)) {
        return;
    }
    let clone = submission.clone();
    switch (owner.type) {
        case "ENROLLMENT":
            clone = setStatusByUser(clone, submission.userID, status);
            break;
        case "GROUP":
            clone = setStatusAll(clone, status);
            break;
    }
    const response = await effects.api.client.updateSubmission({
        courseID: state.activeCourse,
        submissionID: submission.ID,
        grades: clone.Grades,
        released: submission.released,
        score: submission.score,
    });
    if (response.error) {
        return;
    }
    submission.Grades = clone.Grades;
    state.submissionsForCourse.update(owner, submission);
};
export const updateGrade = async ({ state, effects }, { grade, status }) => {
    if (grade.Status === status || !state.selectedSubmission) {
        return;
    }
    if (!confirm(`Are you sure you want to set status ${SubmissionStatus[status]} on this grade?`)) {
        return;
    }
    const clone = state.selectedSubmission.clone();
    clone.Grades = clone.Grades.map(g => {
        if (g.UserID === grade.UserID) {
            g.Status = status;
        }
        return g;
    });
    const response = await effects.api.client.updateSubmission({
        courseID: state.activeCourse,
        submissionID: state.selectedSubmission.ID,
        grades: clone.Grades,
        released: state.selectedSubmission.released,
        score: state.selectedSubmission.score,
    });
    if (response.error) {
        return;
    }
    state.selectedSubmission.Grades = clone.Grades;
    const type = clone.userID ? "ENROLLMENT" : "GROUP";
    switch (type) {
        case "ENROLLMENT":
            state.submissionsForCourse.update({ type, id: clone.userID }, clone);
            break;
        case "GROUP":
            state.submissionsForCourse.update({ type, id: clone.groupID }, clone);
            break;
    }
};
export const updateEnrollment = async ({ state, actions, effects }, { enrollment, status }) => {
    if (!enrollment.user) {
        return;
    }
    if (status === Enrollment_UserStatus.NONE) {
        const proceed = await actions.internal.isEmptyRepo({ userID: enrollment.userID, courseID: enrollment.courseID });
        if (!proceed) {
            return;
        }
    }
    let confirmed = false;
    switch (status) {
        case Enrollment_UserStatus.NONE:
            confirmed = confirm("WARNING! Rejecting a student is irreversible. Are you sure?");
            break;
        case Enrollment_UserStatus.STUDENT:
            confirmed = isPending(enrollment) || confirm(`Warning! ${enrollment.user.Name} is a teacher. Are sure you want to demote?`);
            break;
        case Enrollment_UserStatus.TEACHER:
            confirmed = confirm(`Are you sure you want to promote ${enrollment.user.Name} to teacher status?`);
            break;
        case Enrollment_UserStatus.PENDING:
            return;
    }
    if (!confirmed) {
        return;
    }
    const enrollments = state.courseEnrollments[state.activeCourse.toString()] ?? [];
    const found = enrollments.findIndex(e => e.ID === enrollment.ID);
    if (found === -1) {
        return;
    }
    const temp = enrollment.clone();
    temp.status = status;
    const response = await effects.api.client.updateEnrollments({ enrollments: [temp] });
    if (response.error) {
        return;
    }
    if (status === Enrollment_UserStatus.NONE) {
        enrollments.splice(found, 1);
    }
    else {
        enrollments[found].status = status;
    }
};
export const approvePendingEnrollments = async ({ state, actions, effects }) => {
    if (!confirm("Please confirm that you want to approve all students")) {
        return;
    }
    const enrollments = state.pendingEnrollments.map(e => {
        const temp = e.clone();
        temp.status = Enrollment_UserStatus.STUDENT;
        return temp;
    });
    const response = await effects.api.client.updateEnrollments({ enrollments });
    if (response.error) {
        await actions.getEnrollmentsByCourse({ courseID: state.activeCourse, statuses: [Enrollment_UserStatus.PENDING] });
        return;
    }
    for (const enrollment of state.pendingEnrollments) {
        enrollment.status = Enrollment_UserStatus.STUDENT;
    }
};
export const getAssignments = async ({ state, actions }) => {
    await Promise.all(state.enrollments.map(async (enrollment) => {
        if (isPending(enrollment)) {
            return;
        }
        await actions.getAssignmentsByCourse(enrollment.courseID);
    }));
};
export const getAssignmentsByCourse = async ({ state, effects }, courseID) => {
    const response = await effects.api.client.getAssignments({ courseID });
    if (response.error) {
        return;
    }
    state.assignments[courseID.toString()] = response.message.assignments;
};
export const getRepositories = async ({ state, effects }) => {
    await Promise.all(state.enrollments.map(async (enrollment) => {
        if (isPending(enrollment)) {
            return;
        }
        const courseID = enrollment.courseID;
        state.repositories[courseID.toString()] = {};
        const response = await effects.api.client.getRepositories({ courseID });
        if (response.error) {
            return;
        }
        state.repositories[courseID.toString()] = response.message.URLs;
    }));
};
export const getGroup = async ({ state, effects }, enrollment) => {
    const response = await effects.api.client.getGroup({ courseID: enrollment.courseID, groupID: enrollment.groupID });
    if (response.error) {
        return;
    }
    state.userGroup[enrollment.courseID.toString()] = response.message;
};
export const createGroup = async ({ state, actions, effects }, group) => {
    const check = validateGroup(group);
    if (!check.valid) {
        actions.alert({ text: check.message, color: Color.RED, delay: 10000 });
        return;
    }
    const response = await effects.api.client.createGroup({
        courseID: group.courseID,
        name: group.name,
        users: group.users.map(userID => new User({ ID: userID }))
    });
    if (response.error) {
        return;
    }
    state.userGroup[group.courseID.toString()] = response.message;
    state.activeGroup = null;
};
export const getOrganization = async ({ effects }, orgName) => {
    return await effects.api.client.getOrganization({ ScmOrganizationName: orgName });
};
export const editCourse = async ({ actions, effects }, { course }) => {
    const response = await effects.api.client.updateCourse(course);
    if (response.error) {
        return;
    }
    await actions.getCourses();
};
export const loadCourseSubmissions = async ({ state, actions }, courseID) => {
    state.isLoading = true;
    await actions.refreshCourseSubmissions(courseID);
    state.loadedCourse[courseID.toString()] = true;
    state.isLoading = false;
};
export const refreshCourseSubmissions = async ({ state, effects }, courseID) => {
    const userResponse = await effects.api.client.getSubmissionsByCourse({
        CourseID: courseID,
        FetchMode: {
            case: "Type",
            value: SubmissionRequest_SubmissionType.ALL
        }
    });
    const groupResponse = await effects.api.client.getSubmissionsByCourse({
        CourseID: courseID,
        FetchMode: {
            case: "Type",
            value: SubmissionRequest_SubmissionType.GROUP
        }
    });
    if (userResponse.error || groupResponse.error) {
        return;
    }
    state.submissionsForCourse.setSubmissions("USER", userResponse.message);
    state.submissionsForCourse.setSubmissions("GROUP", groupResponse.message);
    for (const submissions of Object.values(userResponse.message.submissions)) {
        for (const submission of submissions.submissions) {
            state.review.reviews.set(submission.ID, submission.reviews);
        }
    }
};
export const getGroupsByCourse = async ({ state, effects }, courseID) => {
    state.groups[courseID.toString()] = [];
    const response = await effects.api.client.getGroupsByCourse({ courseID });
    if (response.error) {
        return;
    }
    state.groups[courseID.toString()] = response.message.groups;
};
export const getUserSubmissions = async ({ state, effects }, courseID) => {
    const id = courseID.toString();
    if (!state.submissions[id]) {
        state.submissions[id] = [];
    }
    const response = await effects.api.client.getSubmissions({
        CourseID: courseID,
        FetchMode: {
            case: "UserID",
            value: state.self.ID,
        },
    });
    if (response.error) {
        return;
    }
    state.assignments[id]?.forEach(assignment => {
        const submission = response.message.submissions.find(s => s.AssignmentID === assignment.ID);
        if (!state.submissions[id][assignment.order - 1]) {
            state.submissions[id][assignment.order - 1] = submission ? submission : new Submission();
        }
    });
};
export const getGroupSubmissions = async ({ state, effects }, courseID) => {
    const enrollment = state.enrollmentsByCourseID[courseID.toString()];
    if (!(enrollment && enrollment.group)) {
        return;
    }
    const response = await effects.api.client.getSubmissions({
        CourseID: courseID,
        FetchMode: {
            case: "GroupID",
            value: enrollment.groupID,
        },
    });
    if (response.error) {
        return;
    }
    state.assignments[courseID.toString()]?.forEach(assignment => {
        const submission = response.message.submissions.find(sbm => sbm.AssignmentID === assignment.ID);
        if (submission && assignment.isGroupLab) {
            state.submissions[courseID.toString()][assignment.order - 1] = submission;
        }
    });
};
export const setActiveCourse = ({ state }, courseID) => {
    state.activeCourse = courseID;
};
export const toggleFavorites = ({ state }) => {
    state.showFavorites = !state.showFavorites;
};
export const setSelectedAssignmentID = ({ state }, assignmentID) => {
    state.selectedAssignmentID = assignmentID;
};
export const setSelectedSubmission = ({ state }, submission) => {
    state.selectedSubmission = submission.clone();
};
export const getSubmission = async ({ state, effects }, { courseID, owner, submission }) => {
    const response = await effects.api.client.getSubmission({
        CourseID: courseID,
        FetchMode: {
            case: "SubmissionID",
            value: submission.ID,
        },
    });
    if (response.error) {
        return;
    }
    state.submissionsForCourse.update(owner, response.message);
    if (state.selectedSubmission && state.selectedSubmission.ID === submission.ID) {
        state.selectedSubmission = response.message;
    }
};
export const rebuildSubmission = async ({ state, actions, effects }, { owner, submission }) => {
    if (!(submission && state.selectedAssignment && state.activeCourse)) {
        return;
    }
    const response = await effects.api.client.rebuildSubmissions({
        courseID: state.activeCourse,
        assignmentID: state.selectedAssignment.ID,
        submissionID: submission.ID,
    });
    if (response.error) {
        return;
    }
    await actions.getSubmission({ courseID: state.activeCourse, submission, owner });
    actions.alert({ color: Color.GREEN, text: 'Submission rebuilt successfully' });
};
export const rebuildAllSubmissions = async ({ effects }, { courseID, assignmentID }) => {
    const response = await effects.api.client.rebuildSubmissions({
        courseID,
        assignmentID,
    });
    return !response.error;
};
export const enroll = async ({ state, effects }, courseID) => {
    const response = await effects.api.client.createEnrollment({
        courseID,
        userID: state.self.ID,
    });
    if (response.error) {
        return;
    }
    const enrolsResponse = await effects.api.client.getEnrollments({
        FetchMode: {
            case: "userID",
            value: state.self.ID,
        }
    });
    if (enrolsResponse.error) {
        return;
    }
    state.enrollments = enrolsResponse.message.enrollments;
};
export const updateGroupStatus = async ({ effects }, { group, status }) => {
    const oldStatus = group.status;
    group.status = status;
    const response = await effects.api.client.updateGroup(group);
    if (response.error) {
        group.status = oldStatus;
    }
};
export const deleteGroup = async ({ state, actions, effects }, group) => {
    if (!confirm("Deleting a group is an irreversible action. Are you sure?")) {
        return;
    }
    const proceed = await actions.internal.isEmptyRepo({ groupID: group.ID, courseID: group.courseID });
    if (!proceed) {
        return;
    }
    const deleteResponse = await effects.api.client.deleteGroup({
        courseID: group.courseID,
        groupID: group.ID,
    });
    if (deleteResponse.error) {
        return;
    }
    state.groups[group.courseID.toString()] = state.groups[group.courseID.toString()].filter(g => g.ID !== group.ID);
};
export const updateGroup = async ({ state, actions, effects }, group) => {
    const response = await effects.api.client.updateGroup(group);
    if (response.error) {
        return;
    }
    const found = state.groups[group.courseID.toString()].find(g => g.ID === group.ID);
    if (found && response.message) {
        Object.assign(found, response.message);
        actions.setActiveGroup(null);
    }
};
export const createOrUpdateCriterion = async ({ effects }, { criterion, assignment }) => {
    const benchmark = assignment.gradingBenchmarks.find(bm => bm.ID === criterion.ID);
    if (!benchmark) {
        return;
    }
    if (criterion.ID) {
        const response = await effects.api.client.updateCriterion(criterion);
        if (response.error) {
            return;
        }
        const index = benchmark.criteria.findIndex(c => c.ID === criterion.ID);
        if (index > -1) {
            benchmark.criteria[index] = criterion;
        }
    }
    else {
        const response = await effects.api.client.createCriterion(criterion);
        if (response.error) {
            return;
        }
        benchmark.criteria.push(response.message);
    }
};
export const createOrUpdateBenchmark = async ({ effects }, { benchmark, assignment }) => {
    const bm = benchmark.clone();
    if (benchmark.ID) {
        const response = await effects.api.client.updateBenchmark(bm);
        if (response.error) {
            return;
        }
        const index = assignment.gradingBenchmarks.indexOf(benchmark);
        if (index > -1) {
            assignment.gradingBenchmarks[index] = benchmark;
        }
    }
    else {
        const response = await effects.api.client.createBenchmark(benchmark);
        if (response.error) {
            return;
        }
        assignment.gradingBenchmarks.push(response.message);
    }
};
export const createBenchmark = async ({ effects }, { benchmark, assignment }) => {
    benchmark.AssignmentID = assignment.ID;
    const response = await effects.api.client.createBenchmark(benchmark);
    if (response.error) {
        return;
    }
    assignment.gradingBenchmarks.push(benchmark);
};
export const deleteCriterion = async ({ effects }, { criterion, assignment }) => {
    if (!criterion) {
        return;
    }
    const benchmark = assignment.gradingBenchmarks.find(bm => bm.ID === criterion?.ID);
    if (!benchmark) {
        return;
    }
    if (!confirm("Do you really want to delete this criterion?")) {
        return;
    }
    const response = await effects.api.client.deleteCriterion(criterion);
    if (response.error) {
        return;
    }
    const index = assignment.gradingBenchmarks.indexOf(benchmark);
    if (index > -1) {
        assignment.gradingBenchmarks.splice(index, 1);
    }
};
export const deleteBenchmark = async ({ effects }, { benchmark, assignment }) => {
    if (benchmark && confirm("Do you really want to delete this benchmark?")) {
        const response = await effects.api.client.deleteBenchmark(benchmark);
        if (response.error) {
            return;
        }
        const index = assignment.gradingBenchmarks.indexOf(benchmark);
        if (index > -1) {
            assignment.gradingBenchmarks.splice(index, 1);
        }
    }
};
export const setActiveEnrollment = ({ state }, enrollment) => {
    state.selectedEnrollment = enrollment ? enrollment : null;
};
export const startSubmissionStream = ({ actions, effects }) => {
    effects.streamService.submissionStream({
        onStatusChange: actions.setConnectionStatus,
        onMessage: actions.receiveSubmission,
        onError: actions.handleStreamError,
    });
};
export const updateAssignments = async ({ actions, effects }, courseID) => {
    const response = await effects.api.client.updateAssignments({ courseID });
    if (response.error) {
        return;
    }
    actions.alert({ text: "Assignments updated", color: Color.GREEN });
};
export const fetchUserData = async ({ state, actions }) => {
    const successful = await actions.getSelf();
    if (!successful) {
        state.isLoading = false;
        return false;
    }
    await actions.getEnrollmentsByUser();
    await actions.getAssignments();
    await actions.getCourses();
    const results = [];
    for (const enrollment of state.enrollments) {
        const courseID = enrollment.courseID;
        if (isStudent(enrollment) || isTeacher(enrollment)) {
            results.push(actions.getUserSubmissions(courseID));
            results.push(actions.getGroupSubmissions(courseID));
            const statuses = isStudent(enrollment) ? [Enrollment_UserStatus.STUDENT, Enrollment_UserStatus.TEACHER] : [];
            results.push(actions.getEnrollmentsByCourse({ courseID, statuses }));
            if (enrollment.groupID > 0) {
                results.push(actions.getGroup(enrollment));
            }
        }
        if (isTeacher(enrollment)) {
            results.push(actions.getGroupsByCourse(courseID));
        }
    }
    await Promise.all(results);
    if (state.self.IsAdmin) {
        await actions.getUsers();
    }
    await actions.getRepositories();
    actions.startSubmissionStream();
    state.isLoading = false;
    return true;
};
export const changeView = async ({ state, effects }, courseID) => {
    const enrollment = state.enrollmentsByCourseID[courseID.toString()];
    if (hasStudent(enrollment.status)) {
        const response = await effects.api.client.getEnrollments({
            FetchMode: {
                case: "userID",
                value: state.self.ID,
            },
            statuses: [Enrollment_UserStatus.TEACHER],
        });
        if (response.error) {
            return;
        }
        if (response.message.enrollments.find(enrol => enrol.courseID === courseID && hasTeacher(enrol.status))) {
            enrollment.status = Enrollment_UserStatus.TEACHER;
        }
    }
    else if (hasTeacher(enrollment.status)) {
        enrollment.status = Enrollment_UserStatus.STUDENT;
    }
};
export const loading = ({ state }) => {
    state.isLoading = !state.isLoading;
};
export const setQuery = ({ state }, query) => {
    state.query = query;
};
export const errorHandler = (context, { method, error }) => {
    if (!error) {
        return;
    }
    if (error.code === Code.Unauthenticated) {
        if (method === "GetUser") {
            return;
        }
        context.actions.alert({
            text: "Your session has expired. Please log in again.",
            color: Color.RED
        });
        localStorage.setItem("alert", "Your session has expired. Please log in again.");
    }
    else {
        const message = context.state.self.IsAdmin ? `${method}: ${error.message}` : error.rawMessage;
        context.actions.alert({
            text: message,
            color: Color.RED
        });
    }
};
export const alert = ({ state }, a) => {
    state.alerts.push({ id: newID(), ...a });
};
export const popAlert = ({ state }, alert) => {
    state.alerts = state.alerts.filter(a => a.id !== alert.id);
};
export const logout = ({ state }) => {
    state.self = new User();
};
export const setAscending = ({ state }, ascending) => {
    state.sortAscending = ascending;
};
export const setSubmissionSort = ({ state }, sort) => {
    if (state.sortSubmissionsBy !== sort) {
        state.sortSubmissionsBy = sort;
    }
    else {
        state.sortAscending = !state.sortAscending;
    }
};
export const clearSubmissionFilter = ({ state }) => {
    state.submissionFilters = [];
};
export const setSubmissionFilter = ({ state }, filter) => {
    if (state.submissionFilters.includes(filter)) {
        state.submissionFilters = state.submissionFilters.filter(f => f !== filter);
    }
    else {
        state.submissionFilters.push(filter);
    }
};
export const setIndividualSubmissionsView = ({ state }, view) => {
    state.individualSubmissionView = view;
};
export const setGroupView = ({ state }, groupView) => {
    state.groupView = groupView;
};
export const setActiveGroup = ({ state }, group) => {
    state.activeGroup = group?.clone() ?? null;
};
export const updateGroupUsers = ({ state }, user) => {
    if (!state.activeGroup) {
        return;
    }
    const group = state.activeGroup;
    const index = group.users.findIndex(u => u.ID === user.ID);
    if (index >= 0) {
        group.users.splice(index, 1);
    }
    else {
        group.users.push(user);
    }
};
export const updateGroupName = ({ state }, name) => {
    if (!state.activeGroup) {
        return;
    }
    state.activeGroup.name = name;
};
export const setConnectionStatus = ({ state }, status) => {
    state.connectionStatus = status;
};
export const setSubmissionOwner = ({ state }, owner) => {
    if (owner instanceof Group) {
        state.submissionOwner = { type: "GROUP", id: owner.ID };
    }
    else {
        const groupID = state.selectedSubmission?.groupID ?? 0n;
        if (groupID > 0) {
            state.submissionOwner = { type: "GROUP", id: groupID };
            return;
        }
        state.submissionOwner = { type: "ENROLLMENT", id: owner.ID };
    }
};
export const updateSubmissionOwner = ({ state }, owner) => {
    state.submissionOwner = owner;
};
